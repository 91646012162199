import { IInitialisable } from "src/scripts/interfaces/IInitialisable";

export class CssCustomProperties implements IInitialisable {
	private windowHelper = window;
	private documentHelper = document;

	// tested
	Init(): void {
		this.windowHelper.addEventListener("resize", () => this.handleWindowResized());
		this.handleWindowResized();
	}

	// tested
	private handleWindowResized() {
		const root = this.documentHelper.documentElement;
		const props = {
			"--cmpt-scrollbar-width": `${this.currentScrollbarWidth(root)}px`,
		};
		if (typeof root.style.setProperty === "function") {
			Object.entries(props).forEach((entry) => {
				root.style.setProperty(entry[0], entry[1]);
			});
		} else {
			root.setAttribute("data-compt-props", JSON.stringify(props));
		}
	}

	// tested
	private currentScrollbarWidth(el: HTMLElement) {
		return this.windowHelper.innerWidth - el.clientWidth;
	}
}
